import { toDecimal, toSnapshot, dinero } from 'dinero.js'

export const formatMoneyIntl = (locale, options = {}) => {
  return function formatter (dineroObject) {
    function transformer ({ value, currency }) {
      return Number(value).toLocaleString(locale, {
        ...options,
        style: 'currency',
        currency: currency.code
      })
    }

    return toDecimal(dineroObject, transformer)
  }
}

export const formatMoneyDefault = (dineroObject) => {
  return toDecimal(dineroObject, ({ value, currency }) => {
    return `${currency.code} ${Number(value).toFixed(currency.exponent as number)}`
  })
}

const formatters = {
  USD: formatMoneyIntl('en-US'),
  EUR: formatMoneyIntl('fr-FR'),
  GBP: formatMoneyIntl('en-GB')
}

export const formatCurrency = (currencyObj) => {
  const dineroObject = dinero(currencyObj)
  const { currency } = toSnapshot(dineroObject)
  const formatFn = formatters[currency.code] || formatMoneyDefault
  return formatFn(dineroObject)
}
