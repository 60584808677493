import { default as indexXj72jxrSl8Meta } from "/vercel/path0/pages/browse/index.vue?macro=true";
import { default as index6m6RR1hWRWMeta } from "/vercel/path0/pages/buy/index.vue?macro=true";
import { default as index5rijxWJXkdMeta } from "/vercel/path0/pages/buy/tickets-[tenant]/event-[eventSlug]/checkout/index.vue?macro=true";
import { default as newN2AMMNkh3RMeta } from "/vercel/path0/pages/buy/tickets-[tenant]/event-[eventSlug]/checkout/new.vue?macro=true";
import { default as successOAtFytTuVYMeta } from "/vercel/path0/pages/buy/tickets-[tenant]/event-[eventSlug]/checkout/success.vue?macro=true";
import { default as detailsUkqykFSs6AMeta } from "/vercel/path0/pages/buy/tickets-[tenant]/event-[eventSlug]/details.vue?macro=true";
import { default as indexkBVoW7C1eEMeta } from "/vercel/path0/pages/buy/tickets-[tenant]/event-[eventSlug]/index.vue?macro=true";
import { default as indexgfDrfqfiWXMeta } from "/vercel/path0/pages/buy/tickets-[tenant]/index.vue?macro=true";
import { default as recover_45ticketsekjD2KMQQ5Meta } from "/vercel/path0/pages/buy/tickets-[tenant]/recover-tickets.vue?macro=true";
import { default as indexdHtxtwOnQwMeta } from "/vercel/path0/pages/developer/index.vue?macro=true";
import { default as collectWCY9TqOI6kMeta } from "/vercel/path0/pages/download-tickets/collect.vue?macro=true";
import { default as indexo0A46pfdObMeta } from "/vercel/path0/pages/download-tickets/index.vue?macro=true";
import { default as landingjvD0ila3JdMeta } from "/vercel/path0/pages/download-tickets/landing.vue?macro=true";
import { default as examplepmgwTif1s0Meta } from "/vercel/path0/pages/example.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
export default [
  {
    name: indexXj72jxrSl8Meta?.name ?? "browse",
    path: indexXj72jxrSl8Meta?.path ?? "/browse",
    meta: indexXj72jxrSl8Meta || {},
    alias: indexXj72jxrSl8Meta?.alias || [],
    redirect: indexXj72jxrSl8Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/browse/index.vue").then(m => m.default || m)
  },
  {
    name: index6m6RR1hWRWMeta?.name ?? "buy",
    path: index6m6RR1hWRWMeta?.path ?? "/buy",
    meta: index6m6RR1hWRWMeta || {},
    alias: index6m6RR1hWRWMeta?.alias || [],
    redirect: index6m6RR1hWRWMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/buy/index.vue").then(m => m.default || m)
  },
  {
    name: index5rijxWJXkdMeta?.name ?? "buy-tickets-tenant-event-eventSlug-checkout",
    path: index5rijxWJXkdMeta?.path ?? "/buy/tickets-:tenant()/event-:eventSlug()/checkout",
    meta: index5rijxWJXkdMeta || {},
    alias: index5rijxWJXkdMeta?.alias || [],
    redirect: index5rijxWJXkdMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/buy/tickets-[tenant]/event-[eventSlug]/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: newN2AMMNkh3RMeta?.name ?? "buy-tickets-tenant-event-eventSlug-checkout-new",
    path: newN2AMMNkh3RMeta?.path ?? "/buy/tickets-:tenant()/event-:eventSlug()/checkout/new",
    meta: newN2AMMNkh3RMeta || {},
    alias: newN2AMMNkh3RMeta?.alias || [],
    redirect: newN2AMMNkh3RMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/buy/tickets-[tenant]/event-[eventSlug]/checkout/new.vue").then(m => m.default || m)
  },
  {
    name: successOAtFytTuVYMeta?.name ?? "buy-tickets-tenant-event-eventSlug-checkout-success",
    path: successOAtFytTuVYMeta?.path ?? "/buy/tickets-:tenant()/event-:eventSlug()/checkout/success",
    meta: successOAtFytTuVYMeta || {},
    alias: successOAtFytTuVYMeta?.alias || [],
    redirect: successOAtFytTuVYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/buy/tickets-[tenant]/event-[eventSlug]/checkout/success.vue").then(m => m.default || m)
  },
  {
    name: detailsUkqykFSs6AMeta?.name ?? "buy-tickets-tenant-event-eventSlug-details",
    path: detailsUkqykFSs6AMeta?.path ?? "/buy/tickets-:tenant()/event-:eventSlug()/details",
    meta: detailsUkqykFSs6AMeta || {},
    alias: detailsUkqykFSs6AMeta?.alias || [],
    redirect: detailsUkqykFSs6AMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/buy/tickets-[tenant]/event-[eventSlug]/details.vue").then(m => m.default || m)
  },
  {
    name: indexkBVoW7C1eEMeta?.name ?? "buy-tickets-tenant-event-eventSlug",
    path: indexkBVoW7C1eEMeta?.path ?? "/buy/tickets-:tenant()/event-:eventSlug()",
    meta: indexkBVoW7C1eEMeta || {},
    alias: indexkBVoW7C1eEMeta?.alias || [],
    redirect: indexkBVoW7C1eEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/buy/tickets-[tenant]/event-[eventSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgfDrfqfiWXMeta?.name ?? "buy-tickets-tenant",
    path: indexgfDrfqfiWXMeta?.path ?? "/buy/tickets-:tenant()",
    meta: indexgfDrfqfiWXMeta || {},
    alias: indexgfDrfqfiWXMeta?.alias || [],
    redirect: indexgfDrfqfiWXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/buy/tickets-[tenant]/index.vue").then(m => m.default || m)
  },
  {
    name: recover_45ticketsekjD2KMQQ5Meta?.name ?? "buy-tickets-tenant-recover-tickets",
    path: recover_45ticketsekjD2KMQQ5Meta?.path ?? "/buy/tickets-:tenant()/recover-tickets",
    meta: recover_45ticketsekjD2KMQQ5Meta || {},
    alias: recover_45ticketsekjD2KMQQ5Meta?.alias || [],
    redirect: recover_45ticketsekjD2KMQQ5Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/buy/tickets-[tenant]/recover-tickets.vue").then(m => m.default || m)
  },
  {
    name: indexdHtxtwOnQwMeta?.name ?? "developer",
    path: indexdHtxtwOnQwMeta?.path ?? "/developer",
    meta: indexdHtxtwOnQwMeta || {},
    alias: indexdHtxtwOnQwMeta?.alias || [],
    redirect: indexdHtxtwOnQwMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/developer/index.vue").then(m => m.default || m)
  },
  {
    name: collectWCY9TqOI6kMeta?.name ?? "download-tickets-collect",
    path: collectWCY9TqOI6kMeta?.path ?? "/download-tickets/collect",
    meta: collectWCY9TqOI6kMeta || {},
    alias: collectWCY9TqOI6kMeta?.alias || [],
    redirect: collectWCY9TqOI6kMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/download-tickets/collect.vue").then(m => m.default || m)
  },
  {
    name: indexo0A46pfdObMeta?.name ?? "download-tickets",
    path: indexo0A46pfdObMeta?.path ?? "/download-tickets",
    meta: indexo0A46pfdObMeta || {},
    alias: indexo0A46pfdObMeta?.alias || [],
    redirect: indexo0A46pfdObMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/download-tickets/index.vue").then(m => m.default || m)
  },
  {
    name: landingjvD0ila3JdMeta?.name ?? "download-tickets-landing",
    path: landingjvD0ila3JdMeta?.path ?? "/download-tickets/landing",
    meta: landingjvD0ila3JdMeta || {},
    alias: landingjvD0ila3JdMeta?.alias || [],
    redirect: landingjvD0ila3JdMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/download-tickets/landing.vue").then(m => m.default || m)
  },
  {
    name: examplepmgwTif1s0Meta?.name ?? "example",
    path: examplepmgwTif1s0Meta?.path ?? "/example",
    meta: examplepmgwTif1s0Meta || {},
    alias: examplepmgwTif1s0Meta?.alias || [],
    redirect: examplepmgwTif1s0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/example.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  }
]