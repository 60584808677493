import '@formkit/themes/genesis'
import { close, down, fileDoc, check, circle, spinner, star, trash, add, arrowUp, arrowDown } from '@formkit/icons'
import { DefaultConfigOptions } from '@formkit/vue'
import { createProPlugin, inputs } from '@formkit/pro'
import '@formkit/pro/genesis'

// Deps for custom theme.
import { generateClasses } from '@formkit/themes'
import theme from './formtheme.js'

const pro = createProPlugin('fk-6e0e149084b', inputs)

const config: DefaultConfigOptions = {
  plugins: [pro],
  icons: { close, down, fileDoc, check, circle, spinner, star, trash, add, arrowUp, arrowDown, checkboxDecorator: check },
  classes: generateClasses(theme) // generates custom theming classes.
}

export default config
