import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'

// Firebase setup on client
export default defineNuxtPlugin(async (nuxtApp) => {
  // Composables
  const firebaseUser = useUser()
  const config = useRuntimeConfig()

  // Firebase config
  const firebaseConfig = {
    apiKey: config.public.firebaseConfigApiKey,
    authDomain: config.public.firebaseConfigAuthDomain,
    // databaseURL: config.public.firebaseConfigDatabaseURL,
    projectId: config.public.firebaseConfigProjectId,
    storageBucket: config.public.firebaseConfigStorageBucket,
    messagingSenderId: config.public.firebaseConfigMessagingSenderId,
    appId: config.public.firebaseConfigAppId,
    measurementId: config.public.firebaseConfigMeasurementId
  }

  const app = initializeApp(firebaseConfig)
  const auth = getAuth(app)
  const analytics = getAnalytics(app)
  const db = getFirestore(app)

  // // Hook nuxt app mounted lifecycle to listen for firebase auth changes and update token
  // nuxtApp.hooks.hook('app:mounted', () => {
  //     // Listen to firebase auth changes
  //     auth.onIdTokenChanged(async (user) => {
  //         if (user) {
  //             // Get an ID token from firebase auth
  //             const token = await user.getIdToken()
  //             // Call server, which will return a cookie set response to set the auth token in the browser with the token.
  //             setServerSession(token)
  //             // Set local firebaseUser after formatting with helper.
  //             firebaseUser.value = { ...user }
  //         }
  //         else {
  //             // Server will return cookie clear response if no token is provided in req.body
  //             setServerSession('')
  //             // Clear local firebaseUser
  //             firebaseUser.value = null
  //         }
  //     })
  // })

  // Provide $helpers so the firebase instance can be accessed globally
  return {
    provide: {
      firebaseApp: app,
      firebaseAuth: auth,
      firebaseAnalytics: analytics,
      firestore: db
    }
  }
})

// /api/session endpoint response sets the auth token in a cookie on the browser
// function setServerSession(token: string) {
//     return $fetch('/api/session', {
//         method: 'POST',
//         body: {
//             token,
//         },
//     })
// }
