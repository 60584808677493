import {
  dinero,
  add,
  toDecimal,
  multiply,
  toSnapshot,
  isZero,
  lessThanOrEqual,
  greaterThanOrEqual,
  lessThan,
  greaterThan,
  isPositive,
  isNegative,
  equal
} from 'dinero.js'
import { GBP, USD } from '@dinero.js/currencies'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      addCurrency: (currencySnap1, currencySnap2) => {
        const d1 = dinero(currencySnap1)
        const d2 = dinero(currencySnap2)

        return toSnapshot(add(d1, d2))
      },
      // Takes a Dinero currency snapshot object and returns it as a string eg £0.40
      displayCurrency: (currencyObj) => {
        if (!currencyObj) { return 0 }
        return formatCurrency(currencyObj)
        // const d = dinero(currencyObj)
        // // return toFormat(d, ({ amount, currency }) => `£${amount.toFixed(currency.exponent)}`)
        // const decimal = toDecimal(d)
        // // return `${toSnapshot(d).currency.code} ${decimal}`;
        // // Convert decimal from a string into a number
        // const numberVal = Number(decimal)
        // return `${
        //   toSnapshot(d).currency.code === 'USD' ? '$' : '£'
        // }${numberVal.toFixed(2)}`
      },
      displayCurrencyNumber: (currencyObj) => {
        if (!currencyObj) { return 0 }
        const d = dinero(currencyObj)
        // return toFormat(d, ({ amount, currency }) => `${amount.toFixed(currency.exponent)}`)
        const decimal = toDecimal(d)
        const numberVal = Number(decimal)
        return numberVal.toFixed(2)
      },
      displayCurrencyInternational: (currencyObj) => {
        if (!currencyObj) { return 0 }
        const d = dinero(currencyObj)
        // return toFormat(d, ({ amount, currency }) => `${currency.code} ${amount.toFixed(currency.exponent)}`)
        const decimal = toDecimal(d)
        return `${toSnapshot(d).currency.code} ${decimal}`
      },
      displayCurrencyForAnalytics: (currencyObj) => {
        if (!currencyObj) { return { currency: 'GBP', value: 0.0 } }
        const d = dinero(currencyObj)
        // const currency = toFormat(d, ({ amount, currency }) => `${currency.code}`)
        const currency = `${toSnapshot(d).currency.code}`
        const value = toDecimal(d)
        return {
          currency,
          value
        }
      },
      getItemValueForAnalytics: (item) => {
        console.log('Calculating value for item: ', item)
        const { fee, price } = item
        const dFee = dinero(fee)
        const dPrice = dinero(price)
        const dValue = add(dFee, dPrice)
        console.log('Total: ', dValue)
        if (!dValue) { return { currency: 'GBP', value: 0.0 } }
        // const currency = toFormat(dValue, ({ amount, currency }) => `${currency.code}`)
        const currency = `${toSnapshot(dValue).currency.code}`
        // const value = toUnit(dValue, { digits: 2, round: down })
        const value = toDecimal(dValue)
        return {
          currency,
          value
        }
      },
      displayCurrencyFromStripeMoney: (amount: number, tcurrency: string) => {
        const currency = tcurrency === 'USD' ? USD : GBP
        const zeroValue = dinero({
          amount: 0,
          currency,
          scale: 2
        })
        if (!amount) {
          const decimal = toDecimal(zeroValue)
          const numberVal = Number(decimal)
          return `${
            toSnapshot(zeroValue).currency.code === 'USD' ? '$' : '£'
          }${numberVal.toFixed(2)}`
        }
        const d = dinero({ amount, currency, scale: 2 })
        const decimal = toDecimal(d)
        const numberVal = Number(decimal)
        return `${
          toSnapshot(d).currency.code === 'USD' ? '$' : '£'
        }${numberVal.toFixed(2)}`
      },
      // Takes a dinero object and returns a JSON version of it.
      currencySnapshot: (currencyObj: any) => {
        return toSnapshot(dinero(currencyObj))
      },
      // Takes a dinero snapshot and multiplies it by a number.
      currencyMultiply: (c: any, n: any) => {
        return multiply(dinero(c), n)
      },
      // Returns true if first currency object param is less than or equal to second currency object param.
      currencyIsEqual: (a: any, b: any) => {
        return equal(dinero(a), dinero(b))
      },
      // Returns true if currency object is zero.
      currencyIsCurrencyZero: (c: any) => {
        return isZero(dinero(c))
      },
      // Returns true if first currency object param is less than or equal to second currency object param.
      currencyIsLessThanOrEqual: (a: any, b: any) => {
        return lessThanOrEqual(dinero(a), dinero(b))
      },
      // Returns true if first currency object param is less than or equal to second currency object param.
      currencyIsGreaterThanOrEqual: (a: any, b: any) => {
        return greaterThanOrEqual(dinero(a), dinero(b))
      },
      // Returns true if first currency object param is less than second currency object param.
      currencyIsLessThan: (a: any, b: any) => {
        return lessThan(dinero(a), dinero(b))
      },
      // Returns true if first currency object param is less than second currency object param.
      currencyIsGreaterThan: (a: any, b: any) => {
        return greaterThan(dinero(a), dinero(b))
      },
      // Returns true if currency object is positive
      currencyIsPositive: (c: any) => {
        return isPositive(dinero(c))
      },
      // Returns true if currency object is negative
      currencyIsNegative: (c: any) => {
        return isNegative(dinero(c))
      }
    }
  }
})
