import revive_payload_client_SbPW5JctBu from "/vercel/path0/node_modules/.pnpm/nuxt@3.6.2_@types+node@20.4.1_eslint@8.44.0_typescript@5.1.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_HE1y7EWAH7 from "/vercel/path0/node_modules/.pnpm/nuxt@3.6.2_@types+node@20.4.1_eslint@8.44.0_typescript@5.1.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_7zNmuBaWmG from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.4.11_typescript@5.1.6_vue@3.3.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import unhead_bYeOLJSfw4 from "/vercel/path0/node_modules/.pnpm/nuxt@3.6.2_@types+node@20.4.1_eslint@8.44.0_typescript@5.1.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_MyO7dmBRnf from "/vercel/path0/node_modules/.pnpm/nuxt@3.6.2_@types+node@20.4.1_eslint@8.44.0_typescript@5.1.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_7mNpmSDdrY from "/vercel/path0/node_modules/.pnpm/@nuxtjs+web-vitals@0.2.6/node_modules/@nuxtjs/web-vitals/dist/runtime/plugin.client.mjs";
import motion_JIOPihgVZm from "/vercel/path0/node_modules/.pnpm/@vueuse+motion@2.0.0_vue@3.3.4/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import plugin_PrETemM31X from "/vercel/path0/node_modules/.pnpm/@nathanchase+nuxt-dayjs-module@1.0.15/node_modules/@nathanchase/nuxt-dayjs-module/dist/runtime/plugin.mjs";
import formkitPlugin_pZqjah0RUG from "/vercel/path0/.nuxt/formkitPlugin.mjs";
import colors_qmEkgiIef3 from "/vercel/path0/node_modules/.pnpm/@nuxthq+ui@2.5.0_vue@3.3.4_webpack@5.88.1/node_modules/@nuxthq/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_B86mNmBton from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.3.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_KWoz2hoLsL from "/vercel/path0/node_modules/.pnpm/nuxt@3.6.2_@types+node@20.4.1_eslint@8.44.0_typescript@5.1.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import vercel_X9S0nty7R0 from "/vercel/path0/plugins/vercel.ts";
import _01_wetixBuild_Z3bPjOKgjr from "/vercel/path0/plugins/01.wetixBuild.ts";
import _02_wetixError_lZDvArcedZ from "/vercel/path0/plugins/02.wetixError.ts";
import displayCurrency_guI5IqQ6cV from "/vercel/path0/plugins/displayCurrency.ts";
import firebase_client_zXNDa0wxFH from "/vercel/path0/plugins/firebase.client.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/plugins/sentry.client.ts";
export default [
  revive_payload_client_SbPW5JctBu,
  router_HE1y7EWAH7,
  plugin_vue3_7zNmuBaWmG,
  components_plugin_KR1HBZs4kY,
  unhead_bYeOLJSfw4,
  prefetch_client_MyO7dmBRnf,
  plugin_client_7mNpmSDdrY,
  motion_JIOPihgVZm,
  plugin_PrETemM31X,
  formkitPlugin_pZqjah0RUG,
  colors_qmEkgiIef3,
  plugin_client_B86mNmBton,
  chunk_reload_client_KWoz2hoLsL,
  vercel_X9S0nty7R0,
  _01_wetixBuild_Z3bPjOKgjr,
  _02_wetixError_lZDvArcedZ,
  displayCurrency_guI5IqQ6cV,
  firebase_client_zXNDa0wxFH,
  sentry_client_shVUlIjFLk
]