// Hooks Nuxt build process
export default defineNuxtPlugin((nuxtApp) => {
  // vite:extendConfig - Allows to extend the Vite default config
  nuxtApp.hook('vite:configResolved', (viteInlineConfig, env) => {
    console.log('~ vite:configResolved ~')
    console.log({ viteInlineConfig, env })
    // // Install Sentry plugin
    // viteInlineConfig.plugins.push(sentryVitePlugin({
    //   // Vite plugin options
    //   org: 'wetix',
    //   project: 'wetix-shop',

    //   // Auth tokens can be obtained from https://sentry.io/settings/account/api/auth-tokens/
    //   // and need `project:releases` and `org:read` scopes
    //   authToken: process.env.SENTRY_AUTH_TOKEN
    // }))
  })
  // vite:extend - Allows to extend the Vite default context
  nuxtApp.hook('vite:extend', (viteInlineConfig) => {
    console.log('~ vite:extend ~')
    console.log({ viteInlineConfig })
  })
  // vite:serverCreated	- Called when the Vite server is created
  nuxtApp.hook('vite:serverCreated', (viteServer, env) => {
    console.log('~ vite:serverCreated ~')
    console.log({ viteInlineConfig, env })
  })
  // vite:compiled - Called after Vite server is compiled
  nuxtApp.hook('vite:compiled	', () => {
    console.log('~ vite:compiled ~')
    console.log({ viteInlineConfig, env })
  })
})
