// Generated by @nuxtjs/dayjs
import dayjs from 'dayjs/esm/index.js'


import duration from 'dayjs/esm/plugin/duration/index.js'
dayjs.extend(duration)
import relativeTime from 'dayjs/esm/plugin/relativeTime/index.js'
dayjs.extend(relativeTime)
import advancedFormat from 'dayjs/esm/plugin/advancedFormat/index.js'
dayjs.extend(advancedFormat)
import weekday from 'dayjs/esm/plugin/weekday/index.js'
dayjs.extend(weekday)
import localizedFormat from 'dayjs/esm/plugin/localizedFormat/index.js'
dayjs.extend(localizedFormat)

